import * as React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet";
import favicon from "../images/icons/favicon.png";
import TopNavBar from "../components/TopNavBar";
import logo from "../images/thunderstock-logo.svg";
import Button from "@mui/material/Button";
// styles
const pageStyles = {
  color: "white",
  textAlign: "center",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif"
}

const logoStyles = {
  width: "80px",
  marginTop: "100px",
  margin: "24px",
  marginRight: 0
}

const H1 = {
  margin: 0,
  fontSize: "80px",
  fontWeight: 450,
}

const H3 = {
  fontSize: "30px",
  fontWeight: "normal",
}

const paragraphStyles = {
  marginBottom: 48,
}

const BtnStyle = {
  marginTop: "30px",
  width: 151,
  height: 40,
  textTransform: "none",
  fontSize: 20,
  color: "white",
  fontFamily: "League Spartan, Roboto, sans-serif, serif",
  borderColor: "#FF9800"
};

// markup
const NotFoundPage = () => {

  return (
    <div style={pageStyles}>
      <Helmet>
        <title>Page Not Found</title>
        <link rel="canonical" href="http://thunderstock.nl" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <TopNavBar />
      <div>
        <div>
          <Link to="/">
            <img src={logo} alt="logo" style={logoStyles}/>
          </Link>
          <br/>
        </div>
        <h1 style={H1}>404</h1>
        <h3 style={H3}>Page Not Found</h3>
        <p style={paragraphStyles}>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">😔</span>{" "}
          The page you are looking for does not exist.
          <br/>
          <Button variant="outlined" style={BtnStyle}>
            <Link to="/"
                style={{ textDecoration: "none", color: "white", fontSize: "20px" }}>
              Go Home
            </Link>
          </Button>
        </p>
      </div>
    </div>
  )
}

export default NotFoundPage
